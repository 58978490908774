import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Image from "gatsby-image"
import has from "lodash.has"
import get from "lodash.get"
import BackgroundImage from "gatsby-background-image"
import Team from "../components/Team"
import CarouselBg from "../components/CarouselBg"

export default function ContactPage({ data }) {
  return (
    <Layout>
      <div>
        <CarouselBg>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-right in-pic-height">
                <div className="in-text-holder">Portfolio</div>
                <div
                  className="in-pic-1 in-pic-height"
                  style={{ float: "right" }}
                >
                  {has(data, "in_pic.sharp.fluid") && (
                    <Image
                      style={{ height: "100%" }}
                      fluid={data.in_pic.sharp.fluid}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </CarouselBg>
        <div className="breadcrums">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Link to="/">Home</Link> - Portfolio
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="content-holder">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 text-center">
                <a
                  href="#"
                  className="port-lilnk"
                  data-toggle="modal"
                  data-target=".bs-example-modal-lg"
                >
                  <Image
                    fixed={data.portfolioImage1.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Donich Dent Stomatology</div>
                </a>
              </div>
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage2.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Asko-med Insurance </div>
                </a>
              </div>
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage3.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Car Rental</div>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage1.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Donich Dent Stomatology</div>
                </a>
              </div>
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage2.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Asko-med Insurance </div>
                </a>
              </div>
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage3.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Car Rental</div>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage1.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Donich Dent Stomatology</div>
                </a>
              </div>
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage2.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Asko-med Insurance </div>
                </a>
              </div>
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage3.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Car Rental</div>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage1.sharp.fixed}
                    className="port-pic-sm"
                  />

                  <div>Donich Dent Stomatology</div>
                </a>
              </div>
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage2.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Asko-med Insurance </div>
                </a>
              </div>
              <div className="col-sm-4 text-center">
                <a href="#" className="port-lilnk">
                  <Image
                    fixed={data.portfolioImage3.sharp.fixed}
                    className="port-pic-sm"
                  />
                  <div>Car Rental</div>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <a href="#" className="allport-2">
                  More
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bs-example-modal-lg"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeModalLabel"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close close-cross"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <img src="/images/donich_pic.jpg" className="big-pic" />
                <div className="site-under">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="site-name">Donich Dent Stomatology</div>
                    </div>
                    <div className="col-sm-3">
                      <a href="#" className="visit-ws">
                        Visit wevsite
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    in_pic: file(publicURL: { regex: "/in_pic_3.png/" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 641) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    portfolioImage1: file(publicURL: { regex: "/port_pic_01.jpg/" }) {
      sharp: childImageSharp {
        fixed(width: 283) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    portfolioImage2: file(publicURL: { regex: "/port_pic_02.jpg/" }) {
      sharp: childImageSharp {
        fixed(width: 283) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    portfolioImage3: file(publicURL: { regex: "/port_pic_03.jpg/" }) {
      sharp: childImageSharp {
        fixed(width: 283) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
